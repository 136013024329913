import React from 'react'

import PropTypes from 'prop-types'

import './testimonial17.css'

const Testimonial17 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="testimonial17-max-width thq-section-max-width">
        <div className="testimonial17-container">
          <h2 className="thq-heading-2">{props.heading1}</h2>
          <span className="testimonial17-text01 thq-body-small">
            {props.content1}
          </span>
        </div>
        <div className="thq-grid-2">
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card testimonial17-card">
                <div className="testimonial17-container02">
                  <img
                    alt={props.author1Alt}
                    src={props.author1Src}
                    className="testimonial17-image"
                  />
                  <div className="testimonial17-container03">
                    <strong className="thq-body-large">
                      {props.author1Name}
                    </strong>
                    <span className="thq-body-small">
                      {props.author1Position}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text04 thq-body-small">
                  {props.review1}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card1"
              >
                <div className="testimonial17-container04">
                  <img
                    alt={props.author2Alt}
                    src={props.author2Src}
                    className="testimonial17-image1"
                  />
                  <div className="testimonial17-container05">
                    <strong className="thq-body-large">
                      {props.author2Name}
                    </strong>
                    <span className="thq-body-small">
                      {props.author2Position}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text07 thq-body-small">
                  {props.review2}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card2"
              >
                <div className="testimonial17-container06">
                  <img
                    alt={props.author3Alt}
                    src={props.author3Src}
                    className="testimonial17-image2"
                  />
                  <div className="testimonial17-container07">
                    <strong className="thq-body-large">
                      {props.author3Name}
                    </strong>
                    <span className="thq-body-small">
                      {props.author3Position}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text10 thq-body-small">
                  {props.review3}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card3"
              >
                <div className="testimonial17-container08">
                  <img
                    alt={props.author4Alt}
                    src={props.author4Src}
                    className="testimonial17-image3"
                  />
                  <div className="testimonial17-container09">
                    <strong className="thq-body-large">
                      {props.author4Name}
                    </strong>
                    <span className="thq-body-small">
                      {props.author4Position}
                    </span>
                  </div>
                </div>
                <span className="testimonial17-text13 thq-body-small">
                  {props.review4}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Testimonial17.defaultProps = {
  author1Src:
    'https://images.unsplash.com/photo-1541562232579-512a21360020?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDI0OTEwN3w&ixlib=rb-4.0.3&q=80&w=1080',
  author2Alt: 'Image of Jane Smith, CTO of Company XYZ',
  review2:
    'The web app exceeded our expectations in terms of functionality and design. Great job!',
  review1:
    'We are extremely satisfied with the web app developed by the team. It has streamlined our processes and improved efficiency.',
  content1:
    'Read what our clients have to say about our web app development services.',
  author4Name: 'Sarah Williams',
  author3Src:
    'https://images.unsplash.com/photo-1583264277135-6f46aa408484?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDI0OTEwOHw&ixlib=rb-4.0.3&q=80&w=1080',
  author1Name: 'John Doe',
  author4Position: 'CFO, Startup PQR',
  author2Src:
    'https://images.unsplash.com/photo-1643732994192-03856731da2d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDI0OTEwOHw&ixlib=rb-4.0.3&q=80&w=1080',
  author1Alt: 'Image of John Doe, CEO of Company ABC',
  review4:
    'I highly recommend this company for any web app development needs. They are experts in their field.',
  review3:
    'Working with this team was a pleasure. They were professional, responsive, and delivered a top-notch web app.',
  author4Alt: 'Image of Sarah Williams, CFO of Startup PQR',
  author1Position: 'CEO, Company ABC',
  author3Alt: 'Image of David Johnson, COO of Organization LMN',
  author3Position: 'COO, Organization LMN',
  author2Name: 'Jane Smith',
  author3Name: 'David Johnson',
  heading1: 'Testimonials',
  author4Src:
    'https://images.unsplash.com/photo-1589156288859-f0cb0d82b065?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMDI0OTEwOHw&ixlib=rb-4.0.3&q=80&w=1080',
  author2Position: 'CTO, Company XYZ',
}

Testimonial17.propTypes = {
  author1Src: PropTypes.string,
  author2Alt: PropTypes.string,
  review2: PropTypes.string,
  review1: PropTypes.string,
  content1: PropTypes.string,
  author4Name: PropTypes.string,
  author3Src: PropTypes.string,
  author1Name: PropTypes.string,
  author4Position: PropTypes.string,
  author2Src: PropTypes.string,
  author1Alt: PropTypes.string,
  review4: PropTypes.string,
  review3: PropTypes.string,
  author4Alt: PropTypes.string,
  author1Position: PropTypes.string,
  author3Alt: PropTypes.string,
  author3Position: PropTypes.string,
  author2Name: PropTypes.string,
  author3Name: PropTypes.string,
  heading1: PropTypes.string,
  author4Src: PropTypes.string,
  author2Position: PropTypes.string,
}

export default Testimonial17
