import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Hero8 from '../components/hero8'
import Stats2 from '../components/stats2'
import Logos1 from '../components/logos1'
import Features1 from '../components/features1'
import Team11 from '../components/team11'
import Footer4 from '../components/footer4'
import './team.css'

const Team = (props) => {
  return (
    <div className="team-container">
      <Helmet>
        <title>Team - Carbon Console</title>
        <meta property="og:title" content="Team - Carbon Console" />
      </Helmet>
      <Navbar8></Navbar8>
      <Hero8></Hero8>
      <Stats2></Stats2>
      <Logos1></Logos1>
      <Features1></Features1>
      <Team11></Team11>
      <Footer4></Footer4>
    </div>
  )
}

export default Team
