import React from 'react'

import PropTypes from 'prop-types'

import './team11.css'

const Team11 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="team11-max-width thq-section-max-width">
        <div className="team11-section-title">
          <span>
            {props.content1 ?? (
              <fragment>
                <span className="team11-text30 thq-body-small">
                  Join Our Team Today!
                </span>
              </fragment>
            )}
          </span>
          <div className="team11-content">
            <h2>
              {props.heading1 ?? (
                <fragment>
                  <span className="team11-text32 thq-heading-2">Our Team</span>
                </fragment>
              )}
            </h2>
            <p>
              {props.content2 ?? (
                <fragment>
                  <span className="thq-body-large team11-text58">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </fragment>
              )}
            </p>
          </div>
        </div>
        <div className="team11-content01">
          <div className="team11-content02">
            <div className="team11-row">
              <div className="team11-container">
                <div className="team11-card">
                  <img
                    alt={props.member1Alt}
                    src={props.member1Src}
                    className="team11-placeholder-image thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="team11-content03">
                    <div className="team11-title">
                      <span>
                        {props.member1 ?? (
                          <fragment>
                            <span className="team11-text49 thq-body-small">
                              John Doe
                            </span>
                          </fragment>
                        )}
                      </span>
                      <span>
                        {props.member1Job ?? (
                          <fragment>
                            <span className="team11-text33 thq-body-small">
                              Lead Developer
                            </span>
                          </fragment>
                        )}
                      </span>
                      <span>
                        {props.member1Email ?? (
                          <fragment>
                            <span className="team11-text48 thq-body-small">
                              placeholder@carbonconsole.com
                            </span>
                          </fragment>
                        )}
                      </span>
                    </div>
                    <span>
                      {props.member1Content ?? (
                        <fragment>
                          <span className="team11-text37 thq-body-small">
                            John is an experienced developer with a passion for
                            creating innovative solutions.
                          </span>
                        </fragment>
                      )}
                    </span>
                  </div>
                  <div className="team11-social-icons">
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="thq-icon-small"
                    >
                      <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 950.8571428571428 1024"
                      className="thq-icon-small"
                    >
                      <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                    </svg>
                  </div>
                </div>
                <div className="team11-card1">
                  <img
                    alt={props.member2Alt}
                    src={props.member2Src}
                    className="team11-placeholder-image1 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="team11-content04">
                    <div className="team11-title1">
                      <span>
                        {props.member2 ?? (
                          <fragment>
                            <span className="team11-text61 thq-body-small">
                              Jane Smith
                            </span>
                          </fragment>
                        )}
                      </span>
                      <span>
                        {props.member2Job ?? (
                          <fragment>
                            <span className="team11-text48 thq-body-small">
                              UI/UX Designer
                            </span>
                          </fragment>
                        )}
                      </span>
                      <span>
                        {props.member2Email ?? (
                          <fragment>
                            <span className="team11-text48 thq-body-small">
                              UI/UX Designer
                            </span>
                          </fragment>
                        )}
                      </span>
                    </div>
                    <span>
                      {props.member2Content ?? (
                        <fragment>
                          <span className="team11-text56 thq-body-small">
                            Jane excels in creating visually appealing designs
                            that enhance user experience.
                          </span>
                        </fragment>
                      )}
                    </span>
                  </div>
                  <div className="team11-social-icons1">
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="thq-icon-small"
                    >
                      <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 950.8571428571428 1024"
                      className="thq-icon-small"
                    >
                      <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="team11-container1">
                <div className="team11-card2">
                  <img
                    alt={props.member3Alt}
                    src={props.member3Src}
                    className="team11-placeholder-image2 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="team11-content05">
                    <div className="team11-title2">
                      <span>
                        {props.member3 ?? (
                          <fragment>
                            <span className="team11-text57 thq-body-small">
                              Alex Johnson
                            </span>
                          </fragment>
                        )}
                      </span>
                      <span>
                        {props.member3Job ?? (
                          <fragment>
                            <span className="team11-text39 thq-body-small">
                              Project Manager
                            </span>
                          </fragment>
                        )}
                      </span>
                      <span>
                        {props.member3Email ?? (
                          <fragment>
                            <span className="team11-text48 thq-body-small">
                              placeholder@carbonconsole.com
                            </span>
                          </fragment>
                        )}
                      </span>
                    </div>
                    <span>
                      {props.member3Content ?? (
                        <fragment>
                          <span className="team11-text47 thq-body-small">
                            Alex ensures projects are delivered on time and meet
                            clients&apos; expectations.
                          </span>
                        </fragment>
                      )}
                    </span>
                  </div>
                  <div className="team11-social-icons2">
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="thq-icon-small"
                    >
                      <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 950.8571428571428 1024"
                      className="thq-icon-small"
                    >
                      <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                    </svg>
                  </div>
                </div>
                <div className="team11-card3">
                  <img
                    alt={props.member4Alt}
                    src={props.member4Src}
                    className="team11-placeholder-image3 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="team11-content06">
                    <div className="team11-title3">
                      <span>
                        {props.member4 ?? (
                          <fragment>
                            <span className="team11-text43 thq-body-small">
                              Sarah Brown
                            </span>
                          </fragment>
                        )}
                      </span>
                      <span>
                        {props.member4Job ?? (
                          <fragment>
                            <span className="team11-text31 thq-body-small">
                              Quality Assurance Specialist
                            </span>
                          </fragment>
                        )}
                      </span>
                      <span>
                        {props.member4Email ?? (
                          <fragment>
                            <span className="team11-text48 thq-body-small">
                              placeholder@carbonconsole.com
                            </span>
                          </fragment>
                        )}
                      </span>
                    </div>
                    <span>
                      {props.member4Content ?? (
                        <fragment>
                          <span className="team11-text40 thq-body-small">
                            Sarah meticulously tests applications to guarantee
                            flawless performance.
                          </span>
                        </fragment>
                      )}
                    </span>
                  </div>
                  <div className="team11-social-icons3">
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="thq-icon-small"
                    >
                      <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 950.8571428571428 1024"
                      className="thq-icon-small"
                    >
                      <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="team11-row1">
              <div className="team11-container2">
                <div className="team11-card4">
                  <img
                    alt={props.member5Alt}
                    src={props.member5Src}
                    className="team11-placeholder-image4 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="team11-content07">
                    <div className="team11-title4">
                      <span>
                        {props.member5 ?? (
                          <fragment>
                            <span className="team11-text63 thq-body-small">
                              Full name
                            </span>
                          </fragment>
                        )}
                      </span>
                      <span>
                        {props.member5Job ?? (
                          <fragment>
                            <span className="team11-text35 thq-body-small">
                              Job title
                            </span>
                          </fragment>
                        )}
                      </span>
                      <span>
                        {props.member5Email ?? (
                          <fragment>
                            <span className="team11-text48 thq-body-small">
                              placeholder@carbonconsole.com
                            </span>
                          </fragment>
                        )}
                      </span>
                    </div>
                    <span>
                      {props.member5Content ?? (
                        <fragment>
                          <span className="team11-text41 thq-body-small">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse varius enim in eros elementum
                            tristique.
                          </span>
                        </fragment>
                      )}
                    </span>
                  </div>
                  <div className="team11-social-icons4">
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="thq-icon-small"
                    >
                      <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 950.8571428571428 1024"
                      className="thq-icon-small"
                    >
                      <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                    </svg>
                  </div>
                </div>
                <div className="team11-card5">
                  <img
                    alt={props.member6Alt}
                    src={props.member6Src}
                    className="team11-placeholder-image5 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="team11-content08">
                    <div className="team11-title5">
                      <span>
                        {props.member6 ?? (
                          <fragment>
                            <span className="team11-text42 thq-body-small">
                              Full name
                            </span>
                          </fragment>
                        )}
                      </span>
                      <span>
                        {props.member6Job ?? (
                          <fragment>
                            <span className="team11-text44 thq-body-small">
                              Job title
                            </span>
                          </fragment>
                        )}
                      </span>
                    </div>
                    <span>
                      {props.member6Content ?? (
                        <fragment>
                          <span className="team11-text52 thq-body-small">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse varius enim in eros elementum
                            tristique.
                          </span>
                        </fragment>
                      )}
                    </span>
                  </div>
                  <div className="team11-social-icons5">
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="thq-icon-small"
                    >
                      <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 950.8571428571428 1024"
                      className="thq-icon-small"
                    >
                      <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="team11-container3">
                {/* <div className="team11-card6">
                  <img
                    alt={props.member7Alt}
                    src={props.member7Src}
                    className="team11-placeholder-image6 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="team11-content09">
                    <div className="team11-title6">
                      <span>
                        {props.member7 ?? (
                          <fragment>
                            <span className="team11-text34 thq-body-small">
                              Full name
                            </span>
                          </fragment>
                        )}
                      </span>
                      <span>
                        {props.member7Job ?? (
                          <fragment>
                            <span className="team11-text51 thq-body-small">
                              Job title
                            </span>
                          </fragment>
                        )}
                      </span>
                    </div>
                    <span>
                      {props.member7Content ?? (
                        <fragment>
                          <span className="team11-text36 thq-body-small">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse varius enim in eros elementum
                            tristique.
                          </span>
                        </fragment>
                      )}
                    </span>
                  </div>
                  <div className="team11-social-icons6">
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="thq-icon-small"
                    >
                      <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 950.8571428571428 1024"
                      className="thq-icon-small"
                    >
                      <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                    </svg>
                  </div>
                </div> */}
                {/* <div className="team11-card7">
                  <img
                    alt={props.member8Alt}
                    src={props.member8Src}
                    className="team11-placeholder-image7 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="team11-content10">
                    <div className="team11-title7">
                      <span>
                        {props.member8 ?? (
                          <fragment>
                            <span className="team11-text45 thq-body-small">
                              Full name
                            </span>
                          </fragment>
                        )}
                      </span>
                      <span>
                        {props.member8Job ?? (
                          <fragment>
                            <span className="team11-text50 thq-body-small">
                              Job title
                            </span>
                          </fragment>
                        )}
                      </span>
                    </div>
                    <span>
                      {props.member8Content ?? (
                        <fragment>
                          <span className="team11-text62 thq-body-small">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse varius enim in eros elementum
                            tristique.
                          </span>
                        </fragment>
                      )}
                    </span>
                  </div>
                  <div className="team11-social-icons7">
                    <svg
                      viewBox="0 0 877.7142857142857 1024"
                      className="thq-icon-small"
                    >
                      <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 950.8571428571428 1024"
                      className="thq-icon-small"
                    >
                      <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                    </svg>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="team11-content11">
            <div className="team11-content12">
              <h2>
                {props.heading2 ?? (
                  <fragment>
                    <span className="thq-heading-2 team11-text38">
                      We’re hiring!
                    </span>
                  </fragment>
                )}
              </h2>
              <span>
                {props.content3 ?? (
                  <fragment>
                    <span className="team11-text53 thq-body-small">
                      <span>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                  </fragment>
                )}
              </span>
            </div>
            <button className="team11-button thq-button-filled">
              <span>
                {props.actionContent ?? (
                  <fragment>
                    <span className="thq-body-small">Open positions</span>
                  </fragment>
                )}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

Team11.defaultProps = {
    heading1: 'Our Team',
    content1: 'Join Our Team Today!',
  
    heading2: 'We’re hiring!',
    content2: 'Dedication, Passion, Commitment',
    
    content3: 'We are looking for talented individuals to join our team and help us shape the future of web design. If you are passionate about technology and innovation, we want to hear from you! Check out our open positions below and apply today.',
    actionContent: 'Open positions',
  
    member1: 'Richard Harris',
    member1Job: 'CEO',
    member1Content:
    'Richard Harris is the dynamic CEO who founded Carbon Console with a mission to revolutionize website design and maintenance services. With a background in business management and a passion for technology, Richard has cultivated a culture of excellence and creativity. Under his leadership, the company has grown exponentially, earning a reputation for delivering top-notch IT solutions. In his spare time, Richard enjoys mentoring young entrepreneurs, exploring new tech innovations, and playing competitive football. He is also an avid chess player, often engaging in local tournaments.',
    member1Alt: 'Image of Richard H',
    member1Src:
    '/images/richard-harris.png',
    member1Email: 'richard.h@carbonconsole.com',
  
    member2: 'William Graham',
    member2Job: 'CTO',
    member2Content:
    'With over 20 years of experience in the tech industry, William Graham is a visionary leader who drives innovation at Carbon Console. As the CTO, he has spearheaded numerous successful projects, seamlessly integrating cutting-edge technologies to enhance client websites. An avid coder since his teenage years, William is also a published author of several influential tech articles and a sought-after speaker at global IT conferences. When not immersed in technology, William enjoys composing electronic music and participating in marathon races, where he combines his love for running and community engagement.',
    member2Alt: 'Image of William Graham',
    member2Src:
    '/images/william-graham.png',
    member2Email: 'william.graham@carbonconsole.com',
    
    member3: 'Thomas Johnson',
    member3Job: 'Infrastructure Head',
    member3Content:
    'Thomas Johnson, our Infrastructure Head, is the backbone of our technical operations. With a decade of experience in managing large-scale IT infrastructures, Thomas ensures that our clients\' websites run smoothly and efficiently. His expertise in cloud computing and network security is unparalleled, and he has a knack for troubleshooting complex issues swiftly. Outside of work, Thomas is a dedicated mountain climber and a tech gadget enthusiast. He also enjoys gardening and has a thriving collection of exotic plants.',
    member3Src:
    '/images/thomas-johnson.png',
    member3Alt: 'Image of Thomas Johnson',
    member3Email: 'thomas.jo@carbonconsole.com',
  
    member4: 'Stew Nord',
    member4Job: 'Quality Assurance Lead',
    member4Content:
    'Stew Nord, the meticulous QA Lead, is known for his keen eye for detail and commitment to quality. With over 15 years in quality assurance, Stew has developed rigorous testing protocols that guarantee our clients\' websites are flawless. His background in software development gives him a unique perspective on bug detection and prevention. Stew is also an amateur photographer, capturing stunning landscapes in his travels around the world. Additionally, he is a passionate cook, experimenting with recipes from different cuisines and sharing his culinary creations with friends and family.',
    member4Src:
    '/images/stew-nord.png',
    member4Alt: 'Image of Stew Nord',
    member4Email: 'stew.no@carbonconsole.com',

    member5: 'Neville Bottom',
    member5Job: 'Marketing Executive',
    member5Content:
    'Neville Bottom is a dynamic marketing executive at Carbon Console, known for his innovative campaigns and strategic vision. With over a decade of experience in digital marketing, Neville has a proven track record of boosting brand visibility and engagement. His creative approach and data-driven mindset make him a key asset in driving Carbon Console\'s growth and market presence.',
    member5Src:
    '/images/neville-bottom.jpeg',
    member5Alt: 'Image of Neville Bottom',
    member5Email: 'neville.bo@carbonconsole.com',

    member6: 'Angela Trish',
    member6Job: 'HR Lead',
    member6Content:
    'Angela Trish is the HR Lead at Carbon Console, where she excels in talent acquisition and employee relations. With a strong background in human resources and a knack for fostering positive workplace culture, Angela ensures that Carbon Console attracts and retains top talent. Her strategic approach and dedication to employee well-being make her a cornerstone of the company\'s success.',
    member6Src:
    '/images/angela-trish.jpeg',
    member6Alt: 'Image of angela trish',
    member6Email: 'angela.tr@carbonconsole.com',
}

Team11.propTypes = {
  member8Alt: PropTypes.string,
  member2Alt: PropTypes.string,
  member7Src: PropTypes.string,
  member6Src: PropTypes.string,
  content1: PropTypes.element,
  member4Job: PropTypes.element,
  heading1: PropTypes.element,
  member1Job: PropTypes.element,
  member4Src: PropTypes.string,
  member7: PropTypes.element,
  member1Src: PropTypes.string,
  member5Job: PropTypes.element,
  member4Alt: PropTypes.string,
  member7Content: PropTypes.element,
  member1Content: PropTypes.element,
  heading2: PropTypes.element,
  member3Job: PropTypes.element,
  member4Content: PropTypes.element,
  member7Alt: PropTypes.string,
  member5Content: PropTypes.element,
  member6: PropTypes.element,
  member4: PropTypes.element,
  member6Job: PropTypes.element,
  member8: PropTypes.element,
  member5Src: PropTypes.string,
  member3Src: PropTypes.string,
  actionContent: PropTypes.element,
  member3Content: PropTypes.element,
  member3Alt: PropTypes.string,
  member2Job: PropTypes.element,
  member1: PropTypes.element,
  member8Job: PropTypes.element,
  member7Job: PropTypes.element,
  member6Content: PropTypes.element,
  content3: PropTypes.element,
  member8Src: PropTypes.string,
  member2Content: PropTypes.element,
  member2Src: PropTypes.string,
  member3: PropTypes.element,
  content2: PropTypes.element,
  member6Alt: PropTypes.string,
  member2: PropTypes.element,
  member1Alt: PropTypes.string,
  member5Alt: PropTypes.string,
  member8Content: PropTypes.element,
  member5: PropTypes.element,
}

export default Team11
