import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import ContactForm3 from '../components/contact-form3'
import Contact14 from '../components/contact14'
import Contact7 from '../components/contact7'
import Footer4 from '../components/footer4'
import './contact.css'

const Contact = (props) => {
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact - Carbon Console</title>
        <meta
          property="og:title"
          content="Contact - Carbon Console"
        />
      </Helmet>
      <Navbar8></Navbar8>
      <ContactForm3></ContactForm3>
      <Contact14></Contact14>
      <Contact7></Contact7>
      <Footer4></Footer4>
    </div>
  )
}

export default Contact
