import React from 'react'

import PropTypes from 'prop-types'

import './footer4.css'

const Footer4 = (props) => {
  return (
    <footer className="footer4-footer7 thq-section-padding">
      <div className="footer4-max-width thq-section-max-width">
        <div className="footer4-content">
          <div className="footer4-logo">
            <img
              alt={props.logoAlt}
              src={props.logoSrc}
              className="footer4-logo1"
            />
          </div>
          <div className="footer4-links">
            <a href={props.link1Href}className="thq-body-small">{props.link1}</a>
            <a href={props.link2Href}className="thq-body-small">{props.link2}</a>
            <a href={props.link3Href}className="thq-body-small">{props.link3}</a>
            <a href={props.link4Href}className="thq-body-small">{props.link4}</a>
            <a href={props.link5Href}className="thq-body-small">{props.link5}</a>
          </div>
        </div>
        <div className="footer4-credits">
          <div className="thq-divider-horizontal"></div>
          <div className="footer4-row">
            <div className="footer4-container">
              <span className="thq-body-small">© 2020 Carbon Console Ltd.</span>
            </div>
            <div className="footer4-footer-links">
              <span className="footer4-text6 thq-body-small">
                {props.privacyLink}
              </span>
              <span className="thq-body-small">{props.termsLink}</span>
              <span className="thq-body-small">{props.cookiesLink}</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer4.defaultProps = {
  privacyLink: 'Privacy Policy',
  link1: 'Home',
  link1Href: '/',
  link2: 'Blog',
  linke2Href: 'https://blog.carbonconsole.com',
  logoAlt: 'Company Logo',
  termsLink: 'About Us',
  link4: 'Twitter',
  link4Href: 'https://x.com/carbon-console',
  link5: 'LinkedIn',
  link5Href: 'https://linkedin.com/carbonconsole',
  link3: 'Contact Us',
  link3Href: '/contact',
  logoSrc: '/images/cclogo.png',
  cookiesLink: 'Cookies Policy',
}

Footer4.propTypes = {
  privacyLink: PropTypes.string,
  link1: PropTypes.string,
  link2: PropTypes.string,
  logoAlt: PropTypes.string,
  termsLink: PropTypes.string,
  link3: PropTypes.string,
  link5: PropTypes.string,
  link4: PropTypes.string,
  logoSrc: PropTypes.string,
  cookiesLink: PropTypes.string,
}

export default Footer4
